import React, { AnimationEvent, KeyboardEvent, ReactText, useState, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, ApplicationState } from '../../store';
import { LineData, VoltagePower } from '../../models';
import { useOpenAnimations } from '../../hooks';
import { Language } from '../../enums';
import { MotorType } from '../../models/MotorType';
import { getLineImagePaths } from '../../services';
import { useTranslate } from '../../hooks';
import { getTranslations } from '../../constants';
import Icon, { IconType } from '../common/Icon';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Button, { ButtonType, LineCategoryType } from '../common/Button';
import Tooltip from '../common/Tooltip';
import Machining from '../../../assets/images/symbols/black/machining.jpg';
import Moving from '../../../assets/images/symbols/black/moving.jpg';
import Positioning from '../../../assets/images/symbols/black/positioning.jpg';
import Processing from '../../../assets/images/symbols/black/processing.jpg';
import Pumping from '../../../assets/images/symbols/black/pumping.jpg';
import LineItemConfigurationButton from './LineItemConfigurationButton';

interface CardProps {
    lineData: LineData,
    cardRef: Ref<HTMLDivElement>,
    propertyHeights: number[]
}

const Card = ({ lineData, cardRef, propertyHeights }: CardProps) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = getTranslations();
    const dtkData = useSelector((state: ApplicationState) => state.selector.dtkData);
    const newIds = useSelector((state: ApplicationState) => state.selector.newIds);
    const allLineData = useSelector((state: ApplicationState) => state.selector.lineData);
    const motorIds = allLineData.map(x => x.id);
    const newMotor = newIds.includes(lineData.id);
    const moreCardOpen = useSelector((state: ApplicationState) => state.selector.moreCardOpen);
    const moreVoltageOpen = useSelector((state: ApplicationState) => state.selector.moreVoltageOpen);
    const isKwSelected = useSelector((state: ApplicationState) => state.selector.filter.isKwSelected);
    const language = useSelector((state: ApplicationState) => state.translation.language);
    const [animationClass, handleAnimationEnd, cardContentRef, cardHeight] = useOpenAnimations<HTMLDivElement>(moreCardOpen);
    const [, , voltageContentRef, voltageHeight] = useOpenAnimations<HTMLDivElement>(moreVoltageOpen);
    const [disappearing, setDisappearing] = useState(false);
    const isDtkFirstScenario = (dtkData?.KEEPALIVEURL && dtkData?.REGION && dtkData?.RESULTURL) ? true : false;

    const handleMoreAnimationEnd = (event: AnimationEvent<HTMLDivElement>) => {
        event.stopPropagation();

        handleAnimationEnd();
    };

    const handleCardAnimationEnd = () => {
        if (newMotor) {
            dispatch(actionCreators.removeNewId(lineData.id));
        } else {
            disappearing && motorIds.includes(lineData.id) && dispatch(actionCreators.addHiddenId(lineData.id));
            setDisappearing(false);
        }
    };


    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, isVoltageAnimation: boolean) => {
        if (event.key === ' ') {
            event.preventDefault();

            isVoltageAnimation ?
                dispatch(actionCreators.toggleMoreVoltage()) :
                dispatch(actionCreators.toggleMoreCard());
        }
    };

    const camelize = (str: string) => {
        return str
            ? str.trim().toLowerCase()
                .replace(' / ', '')
                .replace(/\s(.)/g, character => character.toUpperCase())
                .replace(/([(|-])(.)/g, character => character.toUpperCase())
                .replace(/\s|\W/g, '')
                .replace(/^(.)/, character => character.toLowerCase())
            : '';
    };

    const getCategoryType = (): LineCategoryType => {
        switch (camelize(lineData.category)) {
            case 'standardPerformanceConverter':
                return LineCategoryType.Standard;
            case 'sectorSpecificConverter':
            case 'industrySpecificConverters':
                return LineCategoryType.SectorSpecific;
            case 'distributedConverter':
                return LineCategoryType.Distributed;
            case 'servoConverter':
                return LineCategoryType.Servo;
            case 'highPerformanceConverter':
                return LineCategoryType.HighPerformance;
            default:
                return LineCategoryType.Standard;
        }
    };

    const getPossibleApplications = (application: string) => {
        switch (application) {
            case 'Machining':
                return Machining;
            case 'Moving':
                return Moving;
            case 'Positioning':
                return Positioning;
            case 'Processing':
                return Processing;
            case 'Pumping':
                return Pumping;
        }
    };

    const parseVoltagePowers = (voltages: string) => {
        return voltages
            .split(';')
            .filter(x => x !== '')
            .map(voltagePowerCombination => {
                const splittedVoltagePowerCombination = voltagePowerCombination.split('#');
                const tooltip = translate((translations.lvconverters.item.motors as any)[lineData.kmat ?? '']?.[lineData.category.split(' ')[0].toLowerCase() ?? '']?.[splittedVoltagePowerCombination[2].replace(/\s+/g, '').toLowerCase() ?? '']);

                return {
                    phaseVoltage: `${splittedVoltagePowerCombination[0]} ${splittedVoltagePowerCombination[1]}`,
                    powerRange: splittedVoltagePowerCombination[2],
                    tooltip
                };
            });
    };

    const translateFormat = (format: string) => translate((translations.lvconverters.item.format as any)[camelize(format)]) ?? format;

    const translateControlModes = (controlModes: string) => translate((translations.lvconverters.item.controlModes as any)[camelize(controlModes)]) ?? controlModes;

    const translateProtection = (protection: string) => {
        const splittedProtection = protection.split('/');

        if (splittedProtection.length > 1) {
            const translationResult = translate((translations.lvconverters.item.typeofProtection as any)[camelize(splittedProtection[1])]);
            return `${splittedProtection[0]} / ${translationResult ?? splittedProtection[1]}`;
        } else {
            return splittedProtection[0];
        }
    };

    const parseMotorType = (motorType: string): MotorType[] => {
        const splitResult = motorType.split(')').filter(a => a !== '');

        return splitResult.map(a => {
            const splittedMotorType = a.split('(').map(a => a.trim());

            return splittedMotorType.length === 2 ?
                { motorName: splittedMotorType[0].trim(), motorInformation: splittedMotorType[1].trim().replace(new RegExp(',', 'g'), '<br>') } :
                { motorName: '-/-', motorInformation: '' };
        });
    };

    const getMarketingSite = () => `https://www.siemens.${Language[language] === 'De' ? 'de' : 'com'}/sinamics-${lineData.kmat === 'G120XA' ? 'g120x' : lineData.kmat.toLowerCase()}`;

    const getSupplyVoltageKey = (phaseVoltage: string, powerRange: string) => `${phaseVoltage}-${powerRange}`;

    const renderNameAndActions = () => (
        <div className='name-and-actions'>
            <div className='mlfb' onClick={() => window.open(getMarketingSite(), '_blank')}>
                SINAMICS {lineData.kmat.toUpperCase() === '6RA80' ? 'DCM' : lineData.kmat}
            </div>
            <div>
                <Button type={ButtonType.Tertiary} onClick={() => setDisappearing(true)}>
                    <Icon type={IconType.Trash} />
                </Button>
            </div>
        </div>
    );

    const renderCategoryButton = () => (
        <div className='category-button'>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                <Button type={ButtonType.Primary} lineCategoryType={getCategoryType()} onClick={() => dispatch(actionCreators.setFilter({ category: lineData.category }))}>
                    {translate((translations.lvconverters.category as any)[camelize(lineData.category)])}
                    <Icon type={IconType.Filter} />
                </Button>
            </Flex>
        </div>
    );

    const renderVoltagePowers = (voltagePowers: VoltagePower[]) => (
        voltagePowers.map(voltagePower => (
            <div key={getSupplyVoltageKey(voltagePower.phaseVoltage, voltagePower.powerRange)} className='property-row'>
                <div className='phase-voltage-property'>{voltagePower.phaseVoltage}</div>
                <div className='power-range-property'>{voltagePower.powerRange}</div>
                {voltagePower.tooltip && <Tooltip id={getSupplyVoltageKey(voltagePower.phaseVoltage, voltagePower.powerRange)} text={voltagePower.tooltip} place='left' delayHide={0} html>
                    <div className='voltage-information'>
                        <Icon type={IconType.Information} />
                    </div>
                </Tooltip>}
            </div>
        ))
    );

    const renderSupplyVoltageProperty = (label: ReactText, voltagePowers: VoltagePower[], height: number) => (
        <div className='two-column-property' style={{ height }}>
            <div className='property-label'>
                {label}
            </div>
            <div className='property-values'>
                {renderVoltagePowers(voltagePowers.slice(0, 4))}
                <div className={`more-details ${moreVoltageOpen ? 'opening' : 'closing'}`} ref={voltageContentRef} style={{ height: voltageHeight }}>
                    {renderVoltagePowers(voltagePowers.slice(4))}
                </div>
                {voltagePowers.length > 4 ?
                    <div className='details-toggle more-voltage' onClick={() => dispatch(actionCreators.toggleMoreVoltage())} onKeyDown={e => handleKeyDown(e, true)}>
                        {moreVoltageOpen ? translate(translations.lvconverters.item.less) : translate(translations.lvconverters.item.more)}
                    </div> :
                    <div className='more-voltage'></div>
                }
            </div>
        </div>
    );

    const renderProperty = (label: ReactText, value: ReactText | JSX.Element, height: number) => (
        <div style={{ height }} className='property-container'>
            <div className='property'>
                <div className='property-label'>
                    {label}
                </div>
                <div className='property-value'>
                    {value}
                </div>
            </div>
        </div>
    );

    const renderMotorTypeProperty = (label: ReactText, motorTypes: MotorType[], height: number) => (
        <div style={{ height }} className='two-column-property'>
            <div className='property-label'>
                {label}
            </div>
            <div className='property-values'>
                {motorTypes.map(motorType => (
                    <div key={motorType.motorName} className='property-row'>
                        <div className='motor-name'>{motorType.motorName}</div>
                        <Tooltip id={motorType.motorName} text={motorType.motorInformation} place='left' delayHide={0} html>
                            <div className='voltage-information'>
                                <Icon type={IconType.Information} />
                            </div>
                        </Tooltip>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderPossibleApplications = () => (
        <div className='possible-applications-container'>
            <div className='possible-applications-title'>{translate(translations.lvconverters.application.title)}</div>
            <div className='possible-applications'>
                {lineData.possibleApplications.split(',').map(item => item.trim()).map(application => (
                    <Tooltip id={application} key={application} text={application} delayHide={0}>
                        <div key={application} className='possible-application-icon'>
                            <img src={getPossibleApplications(application)} alt={application} />
                        </div>
                    </Tooltip>
                ))}
            </div>
        </div>
    );

    return (
        lineData && <div className={`card ${newMotor ? 'appearing' : ''} ${disappearing ? 'disappearing' : ''}`} onAnimationEnd={handleCardAnimationEnd}>
            <div className='thumbnail'>
                <img src={getLineImagePaths(lineData.kmat, lineData.category.trim().toLowerCase())} />
            </div>
            <div className='header'>
                {renderNameAndActions()}
                {renderCategoryButton()}                
            </div>
            <div className='details' ref={cardRef}>
                {renderSupplyVoltageProperty(
                    translate(translations.lvconverters.item.supplyVoltagePower),
                    parseVoltagePowers(isKwSelected ? lineData.voltagePowerCombinedKw : lineData.voltagePowerCombinedHp),
                    propertyHeights[0]
                )}
                {renderProperty(translate(translations.lvconverters.item.format.title), translateFormat(lineData.format), propertyHeights[1])}
                {renderProperty(translate(translations.lvconverters.item.typeofProtection.title), translateProtection(lineData.protection), propertyHeights[2])}
                {renderProperty(translate(translations.lvconverters.item.controlModes.title), translateControlModes(lineData.controlModes), propertyHeights[3])}
                <div ref={cardContentRef} className={`more-details ${animationClass}`} style={{ height: cardHeight }} onAnimationEnd={handleMoreAnimationEnd}>
                    {renderProperty(translate(translations.lvconverters.item.energySavingFunctions), lineData.energySavingFunctions, propertyHeights[4])}
                    {renderProperty(translate(translations.lvconverters.item.safetyFunctions), lineData.safety, propertyHeights[5])}
                    {renderProperty(translate(translations.lvconverters.item.communication), lineData.communication, propertyHeights[6])}
                    {renderMotorTypeProperty(translate(translations.lvconverters.item.motorType.title), parseMotorType(lineData.motorType), propertyHeights[7])}
                </div>
                <div className='details-toggle' onClick={() => dispatch(actionCreators.toggleMoreCard())} onKeyDown={e => handleKeyDown(e, false)}>
                    {moreCardOpen ? translate(translations.lvconverters.item.less) : translate(translations.lvconverters.item.more)}
                </div>
            </div>
            {renderPossibleApplications()}
            <LineItemConfigurationButton lineData={lineData} isDtkFirstScenario={isDtkFirstScenario} responseUrl={dtkData?.RESULTURL ?? ''} />
        </div>
    );
};

export default Card;
